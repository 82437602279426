import {Injectable} from '@angular/core';
import {forkJoin, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {SecurityControllerService as CommonSecurityControllerService} from '@matchsource/api-generated/common';
import {SecurityControllerService as SubjectSecurityControllerService} from '@matchsource/api-generated/subject';
import {SecurityControllerService as OrdersSecurityControllerService} from '@matchsource/api-generated/orders';
import {SecurityControllerService as SearchMatchSecurityControllerService} from '@matchsource/api-generated/search-match';

@Injectable({
  providedIn: 'root',
})
export class PermissionsApiService {
  constructor(
    private readonly commonService: CommonSecurityControllerService,
    private readonly subjectService: SubjectSecurityControllerService,
    private readonly ordersService: OrdersSecurityControllerService,
    private readonly searchMatchService: SearchMatchSecurityControllerService
  ) {}

  getPermissions(): Observable<string[]> {
    return forkJoin([
      this.getCommonPermissions(),
      this.getOrderPermissions(),
      this.getSubjectPermissions(),
      this.getSearchMatchPermissions(),
      //TODO: get document, config permissions when the wso2 error on qa1 is fixed
    ]).pipe(map(allPermissions => allPermissions.flat()));
  }

  private getCommonPermissions(): Observable<string[]> {
    return this.commonService.loadPermissions();
  }

  private getSubjectPermissions(): Observable<string[]> {
    return this.subjectService.loadPermissions();
  }

  private getOrderPermissions(): Observable<string[]> {
    return this.ordersService.loadPermissions();
  }

  private getSearchMatchPermissions(): Observable<string[]> {
    return this.searchMatchService.loadPermissions();
  }
}
