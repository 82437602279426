import {Injectable} from '@angular/core';
import {PermissionsState} from './permissions.state';
import {Select, Store} from '@ngxs/store';
import {firstValueFrom, Observable} from 'rxjs';
import {ensureArray} from '@matchsource/utils';
import {filter, map, tap} from 'rxjs/operators';
import {ClearPermissions, LoadPermissions} from './permissions.actions';

@Injectable({
  providedIn: 'root',
})
export class PermissionsService {
  get permissions(): string[] {
    return this.store.selectSnapshot(PermissionsState.permissions);
  }

  @Select(PermissionsState.loaded)
  loaded$: Observable<boolean>;

  constructor(private readonly store: Store) {}

  hasPermissions(permissions: string | string[] = []): boolean {
    return ensureArray(permissions).every((permission: string) => this.permissions.includes(permission));
  }

  hasAnyPermission(permissions: string | string[]) {
    return ensureArray(permissions).some((permission: string) => this.permissions.includes(permission));
  }

  load(): Promise<void> {
    return firstValueFrom(
      this.loaded$.pipe(
        tap(loaded => {
          if (!loaded) {
            this.store.dispatch(new LoadPermissions());
          }
        }),
        filter(loaded => loaded),
        map(() => undefined)
      )
    );
  }

  clear(): void {
    this.store.dispatch(new ClearPermissions());
  }

  reset(): Promise<void> {
    this.clear();
    return this.load();
  }
}
