export class LoadPermissions {
  static readonly type = '[Permissions] Load';
}

export class ClearPermissions {
  static readonly type = '[Permissions] Clear';
}

export class GetPermissions {
  static readonly type = '[Permissions] Get';
}
