import {USER_PERMISSIONS} from '@matchsource/models/permissions';

export const PERMISSIONS_STORAGE_KEY = 'permissions';

const configFactory = (withPermissions: string[] = [], withoutPermissions: string[] = []) => ({
  withPermissions,
  withoutPermissions,
});

export const PERMISSIONS = {
  'coop:search:view': configFactory([
    USER_PERMISSIONS.COOP_ALLOW,
    USER_PERMISSIONS.COOP_LINK_SHOW,
    USER_PERMISSIONS.COOP_SEARCHES_SHOW,
  ]),
  'coop:search:create': configFactory([USER_PERMISSIONS.COOP_ADD_SEARCH_LINK_SHOW]),
  'coop:search:rerun': configFactory([USER_PERMISSIONS.COOP_RERUN_SEARCH_LINK_SHOW]),
  'coop:search:update': configFactory([USER_PERMISSIONS.COOP_ENTER_DATE_LINK_SHOW]),
  'coop:source:view': configFactory([
    USER_PERMISSIONS.COOP_ALLOW,
    USER_PERMISSIONS.COOP_LINK_SHOW,
    USER_PERMISSIONS.COOP_SOURCE_ALLOW,
    USER_PERMISSIONS.COOP_SOURCES_SHOW,
  ]),
  'coop:source:create': configFactory([
    USER_PERMISSIONS.COOP_ADD_SOURCE_ALLOW,
    USER_PERMISSIONS.COOP_ADD_SOURCE_LINK_SHOW,
  ]),
  'coop:source:edit': configFactory([
    USER_PERMISSIONS.COOP_EDIT_SOURCE_ALLOW,
    USER_PERMISSIONS.COOP_EDIT_SOURCE_LINK_SHOW,
  ]),
  'coop:emdis:source:edit': configFactory([
    USER_PERMISSIONS.DONOR_EDIT_ALLOW,
    USER_PERMISSIONS.DONOR_EDIT_LINK_SHOW,
    USER_PERMISSIONS.CORD_EDIT_ALLOW,
    USER_PERMISSIONS.CORD_EDIT_LINK_SHOW,
  ]),
  'cord:view': configFactory(
    [
      USER_PERMISSIONS.CORD_ALLOW,
      USER_PERMISSIONS.CORD_LOOKUP_ALLOW,
      USER_PERMISSIONS.CORD_DETAILS_LINK_SHOW,
      USER_PERMISSIONS.CORD_LOOKUP_LINK_SHOW,
    ],
    [USER_PERMISSIONS.CORD_DETAILS_LINK_HIDE]
  ),
  'cord:unit_report:create': configFactory([USER_PERMISSIONS.CORD_UNIT_REPORT_ALLOW]),
  'bdp:view': configFactory([
    USER_PERMISSIONS.BIOBANK_LOOKUP_ALLOW,
    USER_PERMISSIONS.BIOBANK_DETAILS_LINK_SHOW,
    USER_PERMISSIONS.BIOBANK_EDIT_LINK_SHOW,
    USER_PERMISSIONS.BIOBANK_CORD_ALLOW,
  ]),
  'donor:view': configFactory(
    [
      USER_PERMISSIONS.DONOR_ALLOW,
      USER_PERMISSIONS.DONOR_LOOKUP_ALLOW,
      USER_PERMISSIONS.DONOR_DETAILS_LINK_SHOW,
      USER_PERMISSIONS.DONOR_LOOKUP_LINK_SHOW,
    ],
    [USER_PERMISSIONS.DONOR_DETAILS_LINK_HIDE]
  ),
  'form_due:view': configFactory([USER_PERMISSIONS.FORMS_DUE_ALLOW, USER_PERMISSIONS.FORMS_DUE_LINK_SHOW]),
  'form_due:follow_up_process': configFactory([USER_PERMISSIONS.FORMS_DUE_FOLLOW_UP_PROCESS]),
  [USER_PERMISSIONS.FORM_DUE_RESOLUTION_REQUEST]: configFactory([USER_PERMISSIONS.FORM_DUE_RESOLUTION_REQUEST]),
  'notification:view': configFactory(
    [USER_PERMISSIONS.WORKFLOW_ALLOW, USER_PERMISSIONS.WORKFLOW_LINK_SHOW],
    [USER_PERMISSIONS.WORKFLOW_LINK_HIDE]
  ),
  'order:view': configFactory(
    [
      USER_PERMISSIONS.ORDERS_ALLOW,
      USER_PERMISSIONS.ORDER_DETAILS_ALLOW,
      USER_PERMISSIONS.ORDERS_LINK_SHOW,
      USER_PERMISSIONS.ORDER_FULFILLMENT_LINK_SHOW,
    ],
    [USER_PERMISSIONS.ORDERS_LINK_HIDE, USER_PERMISSIONS.ORDER_FULFILLMENT_LINK_HIDE]
  ),
  'order:cryopreserved_product_shipment_date:view': configFactory([
    USER_PERMISSIONS.ORDER_CRYOPRESERVED_PRODUCT_SHIPMENT_DATE,
  ]),
  'order:create': configFactory([
    USER_PERMISSIONS.CART_ALLOW,
    USER_PERMISSIONS.ADD_TO_CART_BUTTON_SHOW,
    USER_PERMISSIONS.CART_BUTTON_SHOW,
  ]),
  'advanced_lookup:view': configFactory([
    USER_PERMISSIONS.PATIENT_ADVANCED_LOOKUP_ALLOW,
    USER_PERMISSIONS.PATIENT_ADVANCED_LOOKUP_LINK_SHOW,
  ]),
  'recipient:view': configFactory(
    [
      USER_PERMISSIONS.PATIENT_ALLOW,
      USER_PERMISSIONS.PATIENT_LOOKUP_ALLOW,
      USER_PERMISSIONS.PATIENT_LOOKUP_LINK_SHOW,
      USER_PERMISSIONS.PATIENT_DETAILS_LINK_SHOW,
    ],
    [USER_PERMISSIONS.PATIENT_DETAILS_LINK_HIDE]
  ),
  'subref_field:view': configFactory([USER_PERMISSIONS.PATIENT_SUB_REF_FIELD_SHOW]),
  'labref:create': configFactory([USER_PERMISSIONS.PATIENT_LAB_REF_CREATE]),
  'labref:view': configFactory([USER_PERMISSIONS.PATIENT_LAB_REF_VIEW]),
  'recipient:create': configFactory([
    USER_PERMISSIONS.PATIENT_CREATE_ALLOW,
    USER_PERMISSIONS.PATIENT_CREATE_BUTTON_SHOW,
  ]),
  'recipient:in_progress:create': configFactory([
    USER_PERMISSIONS.PATIENT_CREATE_ALLOW,
    USER_PERMISSIONS.PATIENT_CREATE_BUTTON_SHOW,
  ]),
  'recipient:edit': configFactory([
    USER_PERMISSIONS.PATIENT_EDIT_ALLOW,
    USER_PERMISSIONS.ADD_PHENOTYPE_BUTTON_SHOW,
    USER_PERMISSIONS.PATIENT_EDIT_BUTTON_SHOW,
  ]),
  'case:info:view': configFactory([USER_PERMISSIONS.CASE_INFO_VIEW]),
  'user:request:recipient:close': configFactory([USER_PERMISSIONS.PATIENT_CLOSE_CASE]),
  'recipient:patientprocess:create': configFactory([USER_PERMISSIONS.PATIENT_PROCESS_CREATE]),
  'recipient:patientprocess:edit': configFactory([USER_PERMISSIONS.PATIENT_PROCESS_EDIT]),
  'recipient:patientprocess:view': configFactory([USER_PERMISSIONS.PATIENT_PROCESS_VIEW]),
  'recipient:create:submit_button': configFactory([USER_PERMISSIONS.PATIENT_PROCESSES_SUBMIT_BUTTON]),
  'ssa:request:create': configFactory([USER_PERMISSIONS.SSA_REQUEST_CREATE]),
  'ssa:request:view': configFactory([USER_PERMISSIONS.SSA_REQUEST_VIEW]),
  'summary_counts:page:view': configFactory([
    USER_PERMISSIONS.SUMMARY_COUNTS,
    USER_PERMISSIONS.SUMMARY_COUNTS_LINK_SHOW,
  ]),
  'search:view': configFactory([USER_PERMISSIONS.SEARCH_ALLOW, USER_PERMISSIONS.SEARCH_RESULTS_LINK_SHOW]),
  'search:create': configFactory([
    USER_PERMISSIONS.PATIENT_IN_PROGRESS_ALLOW,
    USER_PERMISSIONS.SEARCH_STATUS_ALLOW,
    USER_PERMISSIONS.SEARCH_RUN_BUTTON_SHOW,
  ]),
  'search:status:view': configFactory([USER_PERMISSIONS.SEARCH_STATUS_BUTTON_SHOW]),
  'reactivate_button:view': configFactory([USER_PERMISSIONS.PATIENT_REACTIVATE_BUTTON_SHOW]),
  'graphmatching:view': configFactory([USER_PERMISSIONS.DOTS_SEARCH_RESULTS]),
  'secondary_code:view': configFactory([USER_PERMISSIONS.SECONDARY_CODE_ALLOW]),
  'manage_secondary_code:view': configFactory([
    USER_PERMISSIONS.SECONDARY_CODE_LINK_SHOW,
    USER_PERMISSIONS.SECONDARY_CODE_PAGES_VIEW,
  ]),
  'secondary_code:create': configFactory([
    USER_PERMISSIONS.SECONDARY_CODE_ADD_ALLOW,
    USER_PERMISSIONS.SECONDARY_CODE_ADD_BUTTON_SHOW,
  ]),
  'secondary_code:edit': configFactory([
    USER_PERMISSIONS.SECONDARY_CODE_EDIT_ALLOW,
    USER_PERMISSIONS.SECONDARY_CODE_EDIT_BUTTON_SHOW,
  ]),
  'workup:in_progress:view': configFactory([
    USER_PERMISSIONS.WORKUP_IN_PROGRESS_ALLOW,
    USER_PERMISSIONS.WORKUP_IN_PROGRESS_TAB_SHOW,
  ]),
  'opl:view': configFactory([USER_PERMISSIONS.OPL_VIEW]),
  'opl:create': configFactory([USER_PERMISSIONS.OPL_CREATE]),
  'opl:edit': configFactory([USER_PERMISSIONS.OPL_EDIT]),
  'opl:remove': configFactory([USER_PERMISSIONS.OPL_REMOVE]),
  'opl_ssa:create': configFactory([USER_PERMISSIONS.OPL_SSA_CREATE]),
  'opl_ssa:edit': configFactory([USER_PERMISSIONS.OPL_SSA_EDIT]),
  'opl_ssa:remove': configFactory([USER_PERMISSIONS.OPL_SSA_REMOVE]),
  'bp:contracted:view': configFactory([USER_PERMISSIONS.BP_VIEW_CONTRACTED]),
  'bp:noncontracted:view': configFactory([USER_PERMISSIONS.BP_VIEW_NON_CONTRACTED]),

  'settings:view': configFactory([USER_PERMISSIONS.SEARCH_EXPORT_SETTINGS_VIEW]),

  'settings:search_export:edit': configFactory([
    USER_PERMISSIONS.SEARCH_EXPORT_SETTINGS_EDIT,
    USER_PERMISSIONS.SETTINGS_EDIT,
  ]),

  'settings:notification_suppress:view': configFactory([USER_PERMISSIONS.NOTIFICATION_SETTINGS_VIEW]),

  'settings:notification_suppress:edit': configFactory([
    USER_PERMISSIONS.NOTIFICATION_SETTINGS_EDIT,
    USER_PERMISSIONS.SETTINGS_EDIT,
  ]),

  'feature_toggles:edit:view': configFactory([USER_PERMISSIONS.FEATURE_TOGGLE_SETTINGS_EDIT]),

  'settings:ssa_opl_request:edit': configFactory([USER_PERMISSIONS.SSA_OPL_REQUEST_SETTINGS_EDIT]),

  'announcement:view': configFactory([USER_PERMISSIONS.ANNOUNCEMENT_VIEW]),

  'announcement:edit': configFactory([USER_PERMISSIONS.ANNOUNCEMENT_EDIT]),

  'announcement:create': configFactory([USER_PERMISSIONS.ANNOUNCEMENT_CREATE]),

  'announcement:delete': configFactory([USER_PERMISSIONS.ANNOUNCEMENT_DELETE]),
  'workup:international_form_doc:create': configFactory([USER_PERMISSIONS.WORKUP_INTERNATIONAL_FORMS_CREATE]),

  'duplicates:view': configFactory([USER_PERMISSIONS.DUPLICATES_VIEW]),
  'user:request:order_wmda:create': configFactory([USER_PERMISSIONS.REQUEST_WMDA_DONOR]),

  'recipient:transfer': configFactory([USER_PERMISSIONS.RECIPIENT_TRANSFER]),

  'notification_tile:view': configFactory([USER_PERMISSIONS.NOTIFICATION_VIEW]),
  'autosave:create:edit:view:delete': configFactory([USER_PERMISSIONS.AUTO_SAVE]),

  'hla:summary_report:counts': configFactory([USER_PERMISSIONS.HLA_REPORT_COUNTS]),
  'hla:summary_report:view': configFactory([USER_PERMISSIONS.HLA_REPORT_VIEW]),
  'hla:summary_report:edit': configFactory([USER_PERMISSIONS.HLA_REPORT_EDIT]),
  'hla:history:view': configFactory([USER_PERMISSIONS.HLA_HISTORY_TAB]),
  'order:international_form_doc:view': configFactory([USER_PERMISSIONS.ORDER_INTERNATIONAL_FORMS_VIEW]),
  'recipient:ct_policy:history:view': configFactory([USER_PERMISSIONS.IDENTITY_HISTORY_VIEW]),
  'recipient:ct_policy:view_without_edit': configFactory([USER_PERMISSIONS.IDENTITY_DISABLED]),
  'recipient:ct_policy:edit': configFactory([USER_PERMISSIONS.RECIPIENT_CT_POLICY_EDIT]),
  'programs:view': configFactory([USER_PERMISSIONS.SOURCE_ENHANCEMENT_PROGRAMS_VIEW]),
  'omidubicel:search:create': configFactory([USER_PERMISSIONS.OMIDUBICEL_SEARCH]),
  'looker_reports:view': configFactory([USER_PERMISSIONS.LOOOKER_REPORTS_VIEW]),
  'recipient:antibody_profile:view': configFactory([USER_PERMISSIONS.ANTIBODY_PROFILE_VIEW]),
  'recipient:antibody_profile:edit': configFactory([USER_PERMISSIONS.ANTIBODY_PROFILE_EDIT]),
  'order:important_dates:view': configFactory([USER_PERMISSIONS.ORDER_IMPORTANT_DATES_VIEW]),

  [USER_PERMISSIONS.ACCESS_TO_PROTECTED_AREA]: configFactory([USER_PERMISSIONS.ACCESS_TO_PROTECTED_AREA]),
  [USER_PERMISSIONS.EDIT_VACCINATION_DETAILS]: configFactory([USER_PERMISSIONS.EDIT_VACCINATION_DETAILS]),
  [USER_PERMISSIONS.SAMPLE_INSTRUCTION_VIEW]: configFactory([USER_PERMISSIONS.SAMPLE_INSTRUCTION_VIEW]),
};
