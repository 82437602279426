import {Injectable} from '@angular/core';
import {Action, NgxsOnInit, Selector, State, StateContext} from '@ngxs/store';
import {ClearPermissions, GetPermissions, LoadPermissions} from './permissions.actions';
import {PermissionsApiService} from '@matchsource/api/permissions';
import {tap} from 'rxjs/operators';
import {uniq} from '@matchsource/utils';
import {PERMISSIONS, PERMISSIONS_STORAGE_KEY} from './constants';
import {SessionStorageService} from 'ngx-webstorage';

export interface PermissionsStateModel {
  permissions: string[];
  loading: boolean;
  loaded: boolean;
}

export const defaultPermissionsState = (): PermissionsStateModel => ({
  permissions: [],
  loading: false,
  loaded: false,
});

@State<PermissionsStateModel>({
  name: 'permissions',
  defaults: defaultPermissionsState(),
})
@Injectable()
export class PermissionsState implements NgxsOnInit {
  constructor(
    private readonly api: PermissionsApiService,
    private readonly storage: SessionStorageService
  ) {}

  ngxsOnInit(ctx: StateContext<any>): void {
    ctx.dispatch(new GetPermissions());
  }

  @Selector([PermissionsState])
  static loaded({loaded}: PermissionsStateModel): boolean {
    return loaded;
  }

  @Selector([PermissionsState])
  static permissions(state: PermissionsStateModel): string[] {
    return state.permissions;
  }

  @Action(LoadPermissions)
  loadPermissions(ctx: StateContext<PermissionsStateModel>) {
    const {loaded, loading} = ctx.getState();

    if (loaded || loading) {
      return;
    }

    ctx.patchState({
      loading: true,
    });

    return this.api.getPermissions().pipe(
      tap(permissions => {
        const permissionsConfig = uniq(this.getPermissions(permissions));

        this.storage.store(PERMISSIONS_STORAGE_KEY, permissionsConfig);

        ctx.setState({
          loading: false,
          loaded: true,
          permissions: permissionsConfig,
        });
      })
    );
  }

  @Action(GetPermissions)
  getPermissionsFromStorage(ctx: StateContext<PermissionsStateModel>) {
    const permissions = this.storage.retrieve(PERMISSIONS_STORAGE_KEY);

    if (!Array.isArray(permissions)) {
      return;
    }

    ctx.patchState({
      permissions,
      loaded: true,
    });
  }

  @Action(ClearPermissions)
  clearPermissions(ctx: StateContext<PermissionsStateModel>) {
    this.storage.clear(PERMISSIONS_STORAGE_KEY);
    ctx.setState(defaultPermissionsState());
  }

  private getPermissions(permissions: string[] = []) {
    return Object.entries(PERMISSIONS).reduce(
      (results, [permission, config]) =>
        permissions.includes(permission)
          ? [...results, ...config.withPermissions]
          : [...results, ...config.withoutPermissions],
      []
    );
  }
}
